/* Check out my website!
https://the-best-codes.github.io/?codepen
*/

@keyframes moveGradient {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 20px);
  /* Reduce height to avoid scrollbars */
  background: linear-gradient(
    45deg,
    rgba(207, 159, 255, 0.8),
    rgba(255, 105, 180, 0.75),
    rgba(255, 192, 203, 0.9)
  );
  background-size: 200% 200%;
  /* Increase the size to allow movement */
  animation: moveGradient 10s ease-in-out infinite alternate;
  /* Adjust the duration as needed */
}

.heart {
  display: inline-block;
  width: 200px;
  aspect-ratio: 1;
  border-image: radial-gradient(red 69%, #0000 70%) 84.5%/50%;
  clip-path: polygon(-41% 0, 50% 91%, 141% 0);
  transition: transform 0.3s ease;
}

.heart:hover {
  transform: scale(1.2);
}

.heart.clicked {
  transform: scale(1.3);
}

.heart.clicked > .heart-text {
  color: white !important;
}

.heart-text {
  margin-top: 40px;
  font-size: 16px;
  user-select: none;
  color: red;
  text-align: center;
  transition: all 0.3s ease;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  /* Reduce width to avoid scrollbars */
  height: calc(100% - 20px);
  /* Reduce height to avoid scrollbars */
  pointer-events: none;
  z-index: -2;
}

.instructions {
  user-select: none;
  pointer-events: none;
  color: white;
  transition: all 0.3s ease;
  text-align: center;
  opacity: 1;
  transform: scale(1.2);
}

/* .heart:hover .instructions {
  transform: translate(-50%, -40px);
  opacity: 1;
} */

/* On mobile devices, show the intructions by default (mobile can't hover) */
@media (max-width: 600px) {
  .instructions {
    transform: translate(-50%, -40px);
    opacity: 1;
  }
}

.heart.clicked .instructions {
  opacity: 0;
}
